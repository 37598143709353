import React from "react";
import Testimonials_icon from "../../assets/svg/Testimonials_icon.svg";
import user_icon from "../../assets/png/Testimonials_user_icon.png";
import Testimonials_img from "../../assets/svg/testimonial_clogo.svg";
import star_icon from "../../assets/svg/stars_icon.svg";
import icon2 from "../../assets/svg/icon2.svg";
import { Button, Col, Container, Row } from "react-bootstrap";

const Testimonials = () => {
  return (
    <div className="py-5 " style={{ background: "#F7F7F7" }}>
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <img src={Testimonials_icon} alt="Testimonials_icon" />
            <h5 className="fs_47 mt-2 fw-bold space_grotesk">
              What People Say About<br></br>{" "}
              <span className="color_orange">Bitutrading</span> Company
            </h5>
          </div>
          <Button className="px-4 mt-4 rounded-5 border-0 space_grotesk text-white fw-semibold py-2 bg_theme">
            More Reviews <span>&rarr;</span>
          </Button>
        </div>
        <Row>
          <Col lg={4} md={6} className="mt-4">
            <div className="bg-white p-4 ">
              <div className="d-flex align-items-end justify-content-between">
                <div className="d-flex gap-4 align-items-center">
                  <img src={user_icon} alt="user_icon" />
                  <div>
                    <h5 className="mb-0 space_grotesk fw-bold">Joshua Send</h5>
                    <p className="mb-0 space_grotesk color_gray">Engineer</p>
                  </div>
                </div>
                <img src={Testimonials_img} alt="Testimonials_img" />
              </div>
              <p className="space_grotesk color_gray mb-0 mt-4 fs_16">
                At the heart of the global landscape, the industry stands as a
                force of progressdriving.
              </p>
              <div className="border mt-4"></div>
              <div className="d-flex mt-2 align-items-center justify-content-between">
                <img src={star_icon} alt="star_icon" />
                <img src={icon2} alt="icon2" />
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} className="mt-4">
            <div className="bg-white p-4 ">
              <div className="d-flex align-items-end justify-content-between">
                <div className="d-flex gap-4 align-items-center">
                  <img src={user_icon} alt="user_icon" />
                  <div>
                    <h5 className="mb-0 space_grotesk fw-bold">
                      Bm Ashik Toren
                    </h5>
                    <p className="mb-0 space_grotesk color_gray">Ceo</p>
                  </div>
                </div>
                <img src={Testimonials_img} alt="Testimonials_img" />
              </div>
              <p className="space_grotesk color_gray mb-0 mt-4 fs_16">
                At the heart of the global landscape, the industry stands as a
                multidimensional force of progress driving.
              </p>
              <div className="border mt-4"></div>
              <div className="d-flex mt-2 align-items-center justify-content-between">
                <img src={star_icon} alt="star_icon" />
                <img src={icon2} alt="icon2" />
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} className="mt-4">
            <div className="bg-white p-4 ">
              <div className="d-flex align-items-end justify-content-between">
                <div className="d-flex gap-4 align-items-center">
                  <img src={user_icon} alt="user_icon" />
                  <div>
                    <h5 className="mb-0 space_grotesk fw-bold">
                      Bm Ashik Toren
                    </h5>
                    <p className="mb-0 space_grotesk color_gray">Ceo</p>
                  </div>
                </div>
                <img src={Testimonials_img} alt="Testimonials_img" />
              </div>
              <p className="space_grotesk color_gray mb-0 mt-4 fs_16">
                At the heart of the global landscape, the industry stands as a
                force of progressdriving.
              </p>
              <div className="border mt-4"></div>
              <div className="d-flex mt-2 align-items-center justify-content-between">
                <img src={star_icon} alt="star_icon" />
                <img src={icon2} alt="icon2" />
              </div>
            </div>
          </Col>
        </Row>
        <div className="bg_theme Subscribe_box mt-5 p-md-5 p-3 rounded-4">
          <div className="d-md-flex align-items-center align-item-center justify-content-between">
            <h3 className="fs_33 space_grotesk text-white">
              Subscribe to get the latest<br></br> updates!
            </h3>
            <div className="d-flex mt-4 mt-md-0 max_input_width justify-content-between bg-white align-items-center p-2 rounded-5">
              <input
                type="text"
                className="w-100 px-3 space_grotesk border-0"
                placeholder="Enter Your Email"
              />
              <button className="bg-black text-white space_grotesk fw-bold px-4 py-2 rounded-5 border-0">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;
