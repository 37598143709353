import React from "react";
import AllOver_image from "../../assets/svg/AllOver_)image.svg";
import icon_image from "../../assets/svg/icon.svg";
import icon1 from "../../assets/svg/icon1.svg";
import { Col, Container, Row } from "react-bootstrap";

const AllOver = () => {
  return (
    <div className="py-5" style={{ background: "#F7F7F7" }}>
      <Container>
        <div className="d-md-flex align-items-start justify-content-between">
          <div>
            <img src={AllOver_image} alt="AllOver_image" />
            <h5 className="fs_47 fw-bold space_grotesk mt-3">
              We Provide <span className=" color_orange"> Our Products</span>{" "}
              <br></br>All Over The World
            </h5>
          </div>
          <button className="mt-3 px-4 py-2 rounded-5 fw-bold text-white bg_theme border-0 space_grotesk">
            Order Now
          </button>
        </div>
        <Row>
          <Col sm={6} lg={4} className="mt-4">
            <div className="over_box h-100  p-4 rounded-4">
              <div className="d-md-flex gap-4 align-items-start">
                <span className=" bg_gray p-3 rounded-1">
                  <img src={icon_image} alt="over_image" />
                </span>
                <div className="mt-3 mt-sm-0">
                  <h5 className="text-white fs_25 mb-2 fw-semibold space_grotesk">
                    Penetration Grade Bitumen
                  </h5>
                  <p className="text-white mb-3 fs_15 space_grotesk ">
                    We can supply penetration grade bitumen with grades of
                    30/40, 40/50, 50/70, 60/70, 80/100.
                  </p>
                  <span className="text-white text-white mb-0 fs_15 space_grotesk ">
                    Read More  <span>&rarr;</span>
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6} lg={4} className="mt-4">
            {" "}
            <div className="over_box h-100  p-4 rounded-4">
              <div className="d-md-flex gap-4 align-items-start">
                <span className=" bg_gray p-3 rounded-1">
                  <img src={icon_image} alt="over_image" />
                </span>
                <div className="mt-3 mt-sm-0">
                  <h5 className="text-white fs_25 mb-2 fw-semibold space_grotesk">
                    Viscosity Grade Bitumen
                  </h5>
                  <p className="text-white mb-3 fs_15 space_grotesk ">
                    We are high-quality and high-volume distributors of
                    viscosity grade bitumen.
                  </p>
                  <span className="text-white text-white mb-0 fs_15 space_grotesk ">
                    Read More  <span>&rarr;</span>
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6} lg={4} className="mt-4">
            {" "}
            <div className="over_box h-100  p-4 rounded-4">
              <div className="d-md-flex gap-4 align-items-start">
                <span className=" bg_gray p-3 rounded-1">
                  <img src={icon_image} alt="over_image" />
                </span>
                <div className="mt-3 mt-sm-0">
                  <h5 className="text-white fs_25 mb-2 fw-semibold space_grotesk">
                    Cutback Bitumen
                  </h5>
                  <p className="text-white mb-3 fs_15 space_grotesk ">
                    Cutback bitumen is a range of binders produced by blending
                    penetration grade bitumen.
                  </p>
                  <span className="text-white text-white mb-0 fs_15 space_grotesk ">
                    Read More  <span>&rarr;</span>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex gap-3 align-items-center mt-5">
          <img src={icon1} alt="icon1" />
          <p className="mb-0 color_gray fs_15 space_grotesk ">
            All bitumen grades sold by Bitutrading are packed in drums, bulk
            containers, or poly bags.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default AllOver;
