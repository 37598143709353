import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import WebHeader from "../common/WebHeader";
import "../../components/homepage/home.css";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero_bg min-vh-100 d-flex flex-column">
      <div>
        <WebHeader displaynone="d-none" />
      </div>

      <Container className="d-flex flex-column justify-content-center flex-grow-1">
        <Row className="">
          <Col md={8} lg={6} className="hero_box p-4 ">
            <p className="mb-0 fs_16 space_grotesk color_orange">
              Love to Build Your Dream
            </p>
            <h5 className="mb-0 text-white fs_52 fw-bold space_grotesk mt-3">
              Quality Products & Services
            </h5>
            <p className="mb-0 color_lightgray mt-3 fs_16 space_grotesk">
              Bituconnect has extensive experience helping contractors with
              projects around the world.
            </p>
            <Link to="/about">
              <Button className="px-4 mt-4 rounded-5 border-0 space_grotesk text-white fw-semibold py-2 bg_theme">
                Track Now <span>&rarr;</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
