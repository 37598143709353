import React from "react";
import Hero from "../components/homepage/Hero";
import "../components/homepage/home.css";
import HomeAboutUsComponent from "../components/homepage/HomeAboutUsComponent";
import OurProducts from "../components/homepage/OurProducts";
import Footer from "../components/common/Footer";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <HomeAboutUsComponent />
      <OurProducts />
      <Footer />
    </div>
  );
};

export default HomePage;
