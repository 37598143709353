import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"; // Import necessary components from React Bootstrap
import logo from "../../assets/svg/Logo.svg";
import facebook_icon from "../../assets/svg/facebook_icon.svg";
import youtube_icon from "../../assets/svg/youtube_icon.svg";
import in_icon from "../../assets/svg/in_icon.svg";
import x_icon from "../../assets/svg/x_icon.svg";
import { Link } from "react-router-dom";

const WebHeader = ({ displaynone, dlgflex, dlgnone }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  return (
    <div className="bg-white py-4">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </Col>
          <Col xs="auto" className="d-flex align-items-center gap-5">
            <div className={` d-none d-xl-flex align-items-center gap-3`}>
              <img src={facebook_icon} alt="Facebook" />
              <img src={youtube_icon} alt="YouTube" />
              <img src={in_icon} alt="LinkedIn" />
              <img src={x_icon} alt="X" />
            </div>
            <ul
              className={`${dlgflex} ${displaynone}  ps-0 gap-4 mb-0  align-items-center space_grotesk`}
            >
              <li>
                <a className="fs_15  color_gray" href="#">
                  Home
                </a>
              </li>
              <li>
                <a className="fs_15  color_gray" href="#">
                  About Us
                </a>
              </li>
              <li>
                <select className="border-0" name="" id="">
                  <option value="Products">Products</option>
                  <option value="Products">Products</option>
                  <option value="Products">Products</option>
                </select>
              </li>
              <li>
                <a className="fs_15  color_gray" href="#">
                  Our Mission
                </a>
              </li>
              <li>
                <a className="fs_15  color_gray" href="#">
                  Contact Us
                </a>
              </li>
            </ul>
            <div
              className={`navmanu d-lg-none position-relative custom_zindex d-block burger  ${
                sidebarVisible ? "active" : ""
              }`}
              onClick={toggleSidebar}
            >
              <div></div>
              <div className=""></div>
              <div></div>
            </div>

            <a target="_blanck" href=" https://bituadmin.testenvapp.com/">
              <Button className="px-4 d-lg-block d-none rounded-5 border-0 space_grotesk text-white fw-semibold py-2 bg_theme">
                Sign in <span>&rarr;</span>
              </Button>
            </a>
          </Col>
          {sidebarVisible && (
            <div
              className={`sidebar d-flex flex-column justify-content-center align-items-center  ${
                sidebarVisible ? "show " : "hide"
              }`}
            >
              <div className="d-lg-none d-flex  align-items-center gap-5 text-center">
                <ul
                  className={` ${dlgnone} d-flex flex-column  ps-0 gap-4 mb-0 align-items-center space_grotesk`}
                >
                  <li className="mt-3">
                    <a className="fs_15  color_gray text-white" href="#">
                      Home
                    </a>
                  </li>
                  <li className="mt-3">
                    <a className="fs_15  color_gray text-white" href="#">
                      About Us
                    </a>
                  </li>
                  <li className="mt-3">
                    <select
                      className="border-0 bg-transparent text-white"
                      name=""
                      id=""
                    >
                      <option value="Products">Products</option>
                      <option value="Products">Products</option>
                      <option value="Products">Products</option>
                    </select>
                  </li>
                  <li className="mt-3">
                    <a className="fs_15  color_gray text-white" href="#">
                      Our Mission
                    </a>
                  </li>
                  <li className="mt-3">
                    <a className="fs_15  color_gray text-white" href="#">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default WebHeader;
