import React from "react";
import footer_image from "../../assets/png/footer_image.png";

const Footer = () => {
  return (
    <div className="footer_bg py-4">
      <p className=" space_grotesk text-white mb-0 text-center">
        © 2024 All Rights Reserved |{" "}
        <span className="color_orange">Bituconnect.com</span>
      </p>
    </div>
  );
};

export default Footer;
